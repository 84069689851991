import { t } from "@lingui/macro";
import {
  CapacityAllocationTypeEnum,
  GlobalWorkspaceSettingsQuery,
  LoginQuery,
  TaskPriority,
  Team,
  TimeOffDurationTypeEnum,
} from "@src/__generated__/urql-graphql";
import { NavigationPage } from "@src/components/widgets/Layout/AppNav/AppNav";
import { TModules } from "@src/config/routes";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { secondsToTime, timeToSeconds } from "@src/utils/time";
import { Time } from "@src/utils/types";
import { compact } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { WorkspaceSettings } from "../models/WorkspaceSettings";
import { TCurrencies, TWorkspaceSettings } from "../models/types";

type TWorkspaceCompany = TWorkspaceSettings["companies"][0];
type TWorkspaceBankAccount =
  TWorkspaceSettings["companies"][0]["bankAccounts"][0];

export class WorkspaceStore implements BaseStore {
  appStore: AppStore;
  @observable.ref settings?: WorkspaceSettings;
  @observable.ref currencies: TCurrencies = [];
  @observable.ref taskPriorities: TaskPriority[] = [];
  @observable companies: TWorkspaceCompany[] = [];
  @observable teams: Team[] = [];

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @computed get defaultAllocationTime(): Record<
    CapacityAllocationTypeEnum,
    { from_time: Time; to_time: Time }
  > {
    const from_time = this.settings?.working_from ?? "00:00";
    const to_time = this.settings?.working_to ?? "00:00";
    const fromTimeIncreasedByHour =
      `${Number(from_time.split(":")[0]) + 1}:00` as Time;

    return {
      [CapacityAllocationTypeEnum.Task]: {
        from_time,
        to_time: fromTimeIncreasedByHour,
      },
      [CapacityAllocationTypeEnum.TimeOff]: { from_time, to_time },
      [CapacityAllocationTypeEnum.HomeOffice]: { from_time, to_time },
      [CapacityAllocationTypeEnum.WeekendWork]: { from_time, to_time },
      [CapacityAllocationTypeEnum.Meeting]: {
        from_time,
        to_time: fromTimeIncreasedByHour,
      },
    };
  }

  @computed get timeOffDefaultValues(): Record<
    TimeOffDurationTypeEnum,
    Record<"from_time" | "to_time", Time>
  > {
    const from_time = this.settings?.working_from ?? "00:00";
    const to_time = this.settings?.working_to ?? "00:00";

    const from_time_in_seconds = timeToSeconds(from_time) ?? 0;
    const to_time_in_seconds = timeToSeconds(to_time) ?? 0;
    const half_of_working_time =
      (to_time_in_seconds - from_time_in_seconds) / 2;
    const middle_of_workday =
      secondsToTime(from_time_in_seconds + half_of_working_time) ?? "00:00";

    return {
      [TimeOffDurationTypeEnum.AllDay]: {
        from_time,
        to_time,
      },
      [TimeOffDurationTypeEnum.FirstHalfOfDay]: {
        from_time,
        to_time: middle_of_workday,
      },
      [TimeOffDurationTypeEnum.SecondHalfOfDay]: {
        from_time: middle_of_workday,
        to_time,
      },
      [TimeOffDurationTypeEnum.Custom]: {
        from_time,
        to_time,
      },
    };
  }

  @action from(src: LoginQuery | GlobalWorkspaceSettingsQuery) {
    this.teams = src.teamSimpleMap;
    this.taskPriorities = src.taskPriorities;
    if (src.currencies) this.currencies = src.currencies;
    if (src.workspaceSettings) {
      this.settings = new WorkspaceSettings(src.workspaceSettings);
      this.companies = src.workspaceSettings.companies;
    }
  }

  hasModuleForPage(_module: TModules): boolean {
    return this.settings?.moduleSettings[_module] ?? false;
  }

  hasMultipleCompanies(selectedCompanyId?: string): boolean {
    return (
      this.companies.filter(
        ({ id, deleted }) => !deleted || id === selectedCompanyId,
      ).length > 1
    );
  }

  @computed get defaultWorkspaceCompany(): TWorkspaceCompany | null {
    return this.hasMultipleCompanies()
      ? null
      : this.companies.find(({ deleted }) => !deleted) ?? null;
  }

  getCompany(companyId: string): TWorkspaceCompany | null {
    return this.companies.find((company) => company.id === companyId) ?? null;
  }

  getCompanyBankAccounts(
    companyId: string,
    selectedAccountId?: string,
  ): TWorkspaceBankAccount[] {
    return (
      this.getCompany(companyId)?.bankAccounts.filter(
        ({ id, deleted }) => selectedAccountId === id || !deleted,
      ) ?? []
    );
  }

  companyHasMultipleBankAccounts(
    companyId: string,
    selectedAccountId?: string,
  ): boolean {
    return this.getCompanyBankAccounts(companyId, selectedAccountId).length > 1;
  }

  getDefaultCompanyBankAccount(
    companyId: string,
  ): TWorkspaceBankAccount | null {
    return !this.companyHasMultipleBankAccounts(companyId)
      ? this.getCompanyBankAccounts(companyId).find(
          ({ deleted }) => !deleted,
        ) ?? null
      : null;
  }

  @computed get APP_NAVIGATION(): NavigationPage[] {
    return [
      {
        page: "tasks",
        label: t`Tasks`,
        icon: "clipboard-check",
        subPages: [
          {
            label: t`Highlighted`,
            url: "/tasks/highlighted",
          },
          {
            label: t`My tasks`,
            url: "/tasks/my",
          },
          {
            label: t`My created tasks`,
            url: "/tasks/my-created",
          },
          {
            label: t`All tasks`,
            url: "/tasks",
          },
        ],
      },
      {
        page: "planovac",
        label: t`Resource planning`,
        icon: "align-left-02",
        subPages: [
          {
            label: t`Resource planning`,
            url: "/resource-planning",
          },
          {
            label: t`My week`,
            url: "/resource-planning/my-week",
          },
        ],
      },
      {
        page: "budgetovac",
        label: t`Proposals`,
        icon: "budget",
        subPages: [
          { label: t`Budgets`, url: "/budgets/export" },
          { label: t`Proposals`, url: "/budgets/proposals" },
          { label: t`Positions`, url: "/budgets/positions" },
          {
            label: t`Hourly rates and commissions`,
            url: "/budgets/hourly-rates",
          },
        ],
      },
      {
        page: "projects",
        label: t`Projects`,
        icon: "grid-01",
        subPages: [
          {
            label: t`List of projects`,
            url: "/projects",
          },
          {
            label: t`Budget exceeded`,
            url: "/projects/budget-exceeded",
          },
          {
            label: t`Work progress`,
            url: "/projects/wip",
          },
        ],
      },
      {
        page: "invoices",
        label: t`Finance`,
        activeOn: ["/expenses"],
        icon: "finance",
        subPages: compact([
          {
            label: t`Request for invoicing`,
            url: "/invoices/for-invoicing",
          },
          this.settings?.moduleSettings?.billing_plan && {
            label: t`Invoicing plan`,
            url: "/invoices/billing-plan",
          },
          {
            label: t`Credit accounts`,
            url: "/invoices/credit-accounts",
          },
          {
            label: t`Proforma invoices`,
            url: "/invoices/proforma-invoices",
          },
          {
            label: t`Outgoing invoices`,
            url: "/invoices/outgoing-invoices",
          },
          {
            label: t`Expenses`,
            url: "/expenses",
          },
        ]),
      },
      {
        page: "clients",
        label: t`Clients & Brands`,
        icon: "building-03",
        activeOn: ["/clients"],
        subPages: [
          {
            label: t`Brands`,
            url: "/brands",
          },
          {
            label: t`Clients`,
            url: "/clients",
          },
        ],
      },
      {
        page: "contractors",
        url: "/contractors",
        label: t`Contractors`,
        icon: "user-square",
      },
      {
        page: "time_tracking",
        url: "/time-tracking/day",
        label: t`Time`,
        icon: "clock",
      },
      {
        page: "reports",
        label: t`Reports`,
        icon: "reports",
        subPages: [
          {
            label: t`Reports`,
            url: "/reports",
          },
          {
            label: t`Utilization report`,
            url: "/reports/utilization",
          },
          {
            label: t`Time reports`,
            url: "/reports/time-report",
          },
          {
            label: t`Users monthly report`,
            url: "/reports/user-monthly-report",
          },
          {
            label: t`Project Billings`,
            url: "/reports/invoiced-by-project",
          },
          {
            label: t`Project Profit`,
            url: "/reports/profitability-by-project",
          },
          {
            label: t`Project Billings & Profit`,
            url: "/reports/invoiced-and-profitability-by-project",
          },
          {
            label: t`Brand Billings`,
            url: "/reports/invoiced-by-brand",
          },
          {
            label: t`Brand Profit`,
            url: "/reports/profitability-by-brand",
          },
          {
            label: t`Brand Billings & Profit`,
            url: "/reports/invoiced-and-profitability-by-brand",
          },
        ],
      },
      {
        page: "documents",
        url: "/documents",
        label: t`Documents`,
        icon: "file-attachment-04",
      },
      {
        page: "users",
        url: "/users",
        label: t`Users`,
        icon: "users-01",
      },
      //{
      //  page: 'settings',
      //  url: '/settings/company',
      //  label: t`Settings`,
      //  icon: 'settings-01',
      //},
    ];
  }
}
