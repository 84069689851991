import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useClipboard,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  InputDatePicker,
  IOption,
  Select,
  TimeInput,
} from "@components/ui-kit";
import { t, Trans } from "@lingui/macro";
import {
  TaskPermissionTypeEnum,
  useCanUpdateOrDeleteTaskMutation,
  useCreateTimeTrackingItemMutation,
} from "@src/__generated__/urql-graphql";
import { BillableBadge } from "@src/components/modules/projects/detail/Header/BillableBadge";
import { TaskStatus } from "@src/components/modules/projects/detail/TaskStatus";
import { Icon } from "@src/components/ui-kit/Icon";
import { TextEditor } from "@src/components/ui-kit/TextEditor/TextEditor";
import { can } from "@src/utils/components/permissions";
import { fieldToInputProps } from "@src/utils/forms/inputHelpers";
import { required } from "@src/utils/forms/validators";
import { useStore } from "@src/utils/hooks";
import { useScreenType } from "@src/utils/hooks/useIsMobile";
import { isTrackingEnabled } from "@src/utils/time-tracking";
import { subDays } from "date-fns";
import { FieldState } from "formstate";
import { computed } from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite";
import { now } from "mobx-utils";
import { Fragment, FunctionComponent, useMemo, useRef } from "react";
import { PopoverActivator as TimeTrackingActivator } from "../../TimeTrackingPopover/components";
import { DuplicateTaskModal } from "../DuplicateTaskModal";
import { TimeTrackingButton } from "./components";

export const TASK_ID_QUERY_KEY = "taskId";

type CommunicationModalHeaderProps = {
  onTimeTrackingItemCreated: (trackedTime: number | undefined) => void;
};

export const CommunicationModalHeader: FunctionComponent<CommunicationModalHeaderProps> =
  observer(function CommunicationModalHeader({ onTimeTrackingItemCreated }) {
    const {
      taskDetailModalStore: store,
      authStore,
      timeTrackingStore,
      UIStore,
    } = useStore();
    const trackingPopoverState = useDisclosure();
    const isTimerRunning = timeTrackingStore.isTimerRunning;
    const { isMobile } = useScreenType();
    const timeInputRef = useRef<HTMLInputElement>(null);
    const state = useLocalObservable(() => ({
      tracked_for_date: new FieldState<Date | undefined>(undefined).validators(
        required,
      ),
      task_id: new FieldState<string | undefined>(undefined),
      notes: new FieldState<string | undefined>(undefined),
      tracked_time: new FieldState<number | undefined>(undefined),
      time_tracking_work_type_id: new FieldState<string | undefined>(undefined),
    }));

    const positionOptions = useMemo(() => {
      const uniqPositions = new Map<string, IOption>();
      const myPositions =
        store.task.value?.positions.filter(
          ({ user }) => user?.id === authStore.user?.id,
        ) ?? [];

      myPositions.forEach(({ timeTrackingWorkType }) => {
        uniqPositions.set(timeTrackingWorkType.id, {
          label: timeTrackingWorkType.title,
          value: timeTrackingWorkType.id,
        });
      });

      return Array.from(uniqPositions.values());
    }, [store.task.value?.positions]);

    const trackingForCurrentTask =
      !!store.task.value?.id &&
      isTimerRunning &&
      timeTrackingStore.trackingForTask(store.task.value.id);
    const trackingSettings =
      store.task.value?.ourWorkBudgetItem?.timeTrackingSettings;
    const trackingForDifferentTask =
      !!store.task.value?.id &&
      isTimerRunning &&
      !timeTrackingStore.trackingForTask(store.task.value.id);
    const trackingEnabled = trackingSettings?.tracking_enabled ?? false;
    const unassignedInTask = !store.task.value?.positions.find(
      ({ user }) => user?.id === authStore.user?.id,
    );

    /**
     * NOTE: Calendar component uses inclusive value for before and exclusive for after
     */
    const disableDatesBefore = computed(() => {
      if (!trackingSettings?.tracking_enabled_from) {
        return undefined;
      }

      return subDays(trackingSettings.tracking_enabled_from, 1);
    }).get();

    /**
     * NOTE: Calendar component uses inclusive value for before and exclusive for after
     */
    const disableDatesAfter = computed(() => {
      if (!trackingSettings?.tracking_enabled_to) {
        return undefined;
      }

      return trackingSettings.tracking_enabled_to;
    }).get();

    const { onCopy } = useClipboard(
      `${location.origin}/notifications?${TASK_ID_QUERY_KEY}=${store.taskId.value}`,
    );
    const toast = useToast();

    const [{ fetching: fetchingCanUpdateTask }, canUpdateTask] =
      useCanUpdateOrDeleteTaskMutation();
    const [{ fetching: creatingItem }, createTimeTrackingItem] =
      useCreateTimeTrackingItemMutation();

    const onCopyTaskLink = () => {
      onCopy();
      toast({
        title: t`Link copied to clipboard`,
        status: "info",
        isClosable: true,
        duration: 3000,
      });
    };

    const handleEditTask = async (
      type: TaskPermissionTypeEnum,
      onSuccess: () => void,
    ) => {
      if (!store.task.value?.id) {
        toast({
          title: <Trans>Can't find task ID.</Trans>,
        });
        return;
      }

      try {
        const { data } = await canUpdateTask({
          task_id: store.task.value?.id,
          type: type,
        });

        if (data?.canUpdateOrDeleteTask) {
          onSuccess();
        } else {
          toast({
            title: <Trans>Insufficient permissions</Trans>,
            status: "error",
          });
        }
      } catch (e) {
        console.error(e);
      }
    };

    const handleStartTracking = async () => {
      if (!state.task_id.$) return;
      if (!state.time_tracking_work_type_id.$) return;
      if (!state.tracked_for_date.$) return;

      try {
        await createTimeTrackingItem({
          user_id: authStore.user?.id,
          task_id: state.task_id.$,
          tracked_for_date: state.tracked_for_date.$,
          tracked_time: state.tracked_time.$,
          time_tracking_work_type_id: state.time_tracking_work_type_id.$,
          note: state.notes.$,
        });
        trackingPopoverState.onClose();
        store.fetchTask?.();
        onTimeTrackingItemCreated(state.tracked_time.$);
        timeTrackingStore.updateTrackedTimeInListing(state.task_id.$);
      } catch {}
    };

    const handleMoveTask = () => {
      handleEditTask(TaskPermissionTypeEnum.Update, () => {
        store.editTaskModal.onOpen({
          moveMode: true,
        });
      });
    };

    const handleOpenTimeTracking = () => {
      if (trackingSettings && isTrackingEnabled(trackingSettings)) {
        state.tracked_for_date.onChange(new Date(now()));
      } else {
        state.tracked_for_date.validate();
      }
      state.task_id.onChange(store.task.value?.id);
      state.time_tracking_work_type_id.onChange(
        store.task.value?.positions.find(
          ({ user }) => user?.id === authStore.user?.id,
        )?.timeTrackingWorkType.id,
      );
      state.tracked_time.onChange(
        timeTrackingStore.runningEntry?.tracked_time_with_running_timer,
      );
      state.notes.onChange(timeTrackingStore.runningEntry?.note ?? undefined);
      trackingPopoverState.onOpen();
    };

    return (
      <Box zIndex="10" bg="white">
        <Flex
          align="center"
          justify="space-between"
          h="16"
          pr={{
            base: "2",
            md: "4",
          }}
          pl={{
            base: "4",
            md: "8",
          }}
          borderBottom="2px"
          borderBottomColor="grey.50"
        >
          <HStack spacing="2">
            {store.task.value && (
              <TaskStatus
                task={{
                  id: store.task.value.id,
                  status: store.task.value.status,
                }}
                isChevronAlwaysVisible
              />
            )}
            {store.isLoading.value ? null : isMobile ? (
              store.task.value?.billable ? (
                <Icon name="currency-dollar-circle" />
              ) : (
                <Icon name="non-billable" />
              )
            ) : (
              <BillableBadge billable={store.task.value?.billable} />
            )}
          </HStack>

          <HStack>
            {store.task.value && (
              <Fragment>
                {isMobile ? (
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label={t`Task options menu button`}
                      icon={<Icon name="dots-horizontal" />}
                      variant="ghost"
                    />
                    <MenuList>
                      <MenuItem
                        icon={
                          <Icon name="link-03" w="5" h="5" color="grey.500" />
                        }
                        onClick={onCopyTaskLink}
                      >
                        <Trans>Copy link</Trans>
                      </MenuItem>
                      {can("task_create_all") && (
                        <MenuItem
                          icon={
                            <Icon name="copy-02" w="5" h="5" color="grey.500" />
                          }
                          onClick={() => {
                            if (!store.task.value?.id) return;
                            UIStore.dialogs.openModal({
                              content: (
                                <DuplicateTaskModal
                                  taskId={store.task.value.id}
                                />
                              ),
                            });
                          }}
                        >
                          <Trans>Duplicate</Trans>
                        </MenuItem>
                      )}
                      <MenuItem
                        icon={<Icon name="move" />}
                        onClick={handleMoveTask}
                      >
                        <Trans>Move task</Trans>
                      </MenuItem>
                      <MenuItem
                        icon={
                          <Icon name="trash-03" w="5" h="5" color="grey.500" />
                        }
                        onClick={() => {
                          handleEditTask(TaskPermissionTypeEnum.Delete, () => {
                            store.deleteModal.open();
                          });
                        }}
                      >
                        <Trans>Delete task</Trans>
                      </MenuItem>
                      <MenuItem
                        icon={
                          <Icon name="edit-02" w="5" h="5" color="grey.500" />
                        }
                        onClick={() => {
                          handleEditTask(TaskPermissionTypeEnum.Update, () => {
                            store.editTaskModal.open();
                          });
                        }}
                      >
                        <Trans>Edit task</Trans>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                ) : (
                  <ButtonGroup colorScheme="grey" variant="ghost">
                    <Tooltip label={t`Copy link`}>
                      <IconButton
                        aria-label={t`Copy link`}
                        icon={
                          <Icon name="link-03" w="5" h="5" color="grey.500" />
                        }
                        onClick={onCopyTaskLink}
                      />
                    </Tooltip>
                    <Tooltip label={t`Duplicate task`}>
                      <IconButton
                        aria-label={t`Duplicate task`}
                        icon={
                          <Icon name="copy-02" w="5" h="5" color="grey.500" />
                        }
                        onClick={() => {
                          if (!store.task.value?.id) return;
                          UIStore.dialogs.openModal({
                            content: (
                              <DuplicateTaskModal
                                taskId={store.task.value.id}
                              />
                            ),
                          });
                        }}
                      />
                    </Tooltip>
                    <Tooltip label={t`Move task`}>
                      <IconButton
                        aria-label={t`Move task`}
                        icon={<Icon name="move" w="5" h="5" color="grey.500" />}
                        onClick={handleMoveTask}
                      />
                    </Tooltip>
                    <Tooltip label={t`Delete task`}>
                      <IconButton
                        aria-label={t`Delete task`}
                        icon={
                          <Icon name="trash-03" w="5" h="5" color="grey.500" />
                        }
                        onClick={() => {
                          handleEditTask(TaskPermissionTypeEnum.Delete, () => {
                            store.deleteModal.open();
                          });
                        }}
                      />
                    </Tooltip>
                    <Button
                      colorScheme="grey"
                      isLoading={fetchingCanUpdateTask}
                      leftIcon={
                        <Icon name="edit-02" w="5" h="5" color="grey.500" />
                      }
                      onClick={() => {
                        handleEditTask(TaskPermissionTypeEnum.Update, () => {
                          store.editTaskModal.open();
                        });
                      }}
                      variant="outline"
                    >
                      <Trans>Edit</Trans>
                    </Button>
                  </ButtonGroup>
                )}
                {can(
                  "timeTracking_create_own",
                  "timeTracking_create_all",
                  "timeTracking_create_team",
                ) && (
                  <HStack>
                    {store.task.value.ourWorkBudgetItem && (
                      <TimeTrackingButton
                        timeTrackingSettings={
                          store.task.value.ourWorkBudgetItem
                            .timeTrackingSettings
                        }
                        taskId={store.task.value.id}
                        positions={store.task.value.positions}
                        variant={isMobile ? "iconButton" : undefined}
                        customButtonTitle={
                          trackingForCurrentTask ? t`Stop` : t`Start`
                        }
                      />
                    )}
                    {trackingForCurrentTask ? (
                      <TimeTrackingActivator />
                    ) : (
                      <Popover
                        isOpen={trackingPopoverState.isOpen}
                        onClose={trackingPopoverState.onClose}
                        onOpen={handleOpenTimeTracking}
                      >
                        <PopoverTrigger>
                          <Box>
                            <Tooltip
                              isDisabled={!unassignedInTask && trackingEnabled}
                              label={
                                trackingForDifferentTask
                                  ? t`You have another tracker running. Starting a new one will pause the previous one.`
                                  : unassignedInTask
                                    ? t`You cannot track time on task where you are not assigned.`
                                    : !trackingEnabled
                                      ? t`Time tracking is disabled for this task`
                                      : undefined
                              }
                            >
                              <Button
                                isDisabled={
                                  unassignedInTask || !trackingEnabled
                                }
                                isLoading={creatingItem}
                                onBlur={() => {
                                  timeInputRef.current?.focus();
                                }}
                              >
                                {isMobile ? (
                                  <Trans>+</Trans>
                                ) : (
                                  <Trans>+ Time</Trans>
                                )}
                              </Button>
                            </Tooltip>
                          </Box>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverBody>
                            <VStack align="end" spacing="2">
                              <InputDatePicker
                                selected={state.tracked_for_date.$}
                                disableDatesAfter={disableDatesAfter}
                                disableDatesBefore={disableDatesBefore}
                                error={state.tracked_for_date.error}
                                onChange={(range) => {
                                  if (!range?.start) return;
                                  state.tracked_for_date.onChange(range.start);
                                }}
                                w="full"
                                inputProps={{
                                  textAlign: "center",
                                }}
                              />
                              <TimeInput
                                ref={timeInputRef}
                                value={state.tracked_time.value}
                                onChange={state.tracked_time.onChange}
                              />
                              {positionOptions.length > 1 && (
                                <Select
                                  options={positionOptions}
                                  value={state.time_tracking_work_type_id.value}
                                  onChange={(posId) => {
                                    state.time_tracking_work_type_id.onChange(
                                      posId,
                                    );
                                  }}
                                />
                              )}
                              <Box w="full">
                                <TextEditor
                                  attachments={[]}
                                  placeholder={t`Notes`}
                                  h="60px"
                                  overflow="hidden"
                                  maxH="60px"
                                  px="3"
                                  w="full"
                                  minHeight="60px"
                                  borderWidth="thin"
                                  initialValue={state.notes.value}
                                  borderColor="grey.200"
                                  borderRadius="base"
                                  transition="border-color 150ms ease-in"
                                  _focusWithin={{
                                    borderColor: "purple.500",
                                  }}
                                  {...fieldToInputProps(state.notes)}
                                />
                              </Box>
                              <Button
                                isLoading={creatingItem}
                                onClick={handleStartTracking}
                              >
                                <Trans>Create</Trans>
                              </Button>
                            </VStack>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    )}
                  </HStack>
                )}
              </Fragment>
            )}
            <IconButton
              aria-label={t`close`}
              colorScheme="grey"
              icon={<Icon name="x-close" w="5" h="5" />}
              onClick={store.drawerState.onClose}
              variant="ghost"
            />
          </HStack>
        </Flex>
      </Box>
    );
  });
