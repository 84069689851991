import { BudgetsExportStore } from "@src/components/modules/budgets/export/Store";
import { SendInvoiceModalStore } from "@src/components/modules/invoices/outgoing/listing/table/SendInvoice/Store";
import { NotificationsStore } from "@src/components/modules/notifications/listing/store";
import { ProjectCreateBudgetItemModalStore } from "@src/components/modules/projects/detail/create-budget-item-modal/store";
import { TasksListingStore } from "@src/components/modules/resource-planning/tasks/listing/TasksListingStore";
import { ProjectCategoryModalStore } from "@src/components/ui-kit/ProjectCategorySelect/ModalStore";
import { ProjectCategorySelectStore } from "@src/components/ui-kit/ProjectCategorySelect/Store";
import { ModalCommunicationStore } from "@src/components/widgets/Modals/ModalCommunication/ModalCommunicationStore";
import { activateI18n } from "@src/services/i18n";
import { AllocationModalStore } from "@src/stores/AllocationModalStore";
import { AuthStore } from "@src/stores/AuthStore";
import { ChangeTimeTrackingWorkTypeModalStore } from "@src/stores/ChangeTimeTrackingWorkTypeModalStore";
import { CompleteCapacityAllocationModalStore } from "@src/stores/CompleteCapacityAllocationModalStore";
import { DeleteAllocationModalStore } from "@src/stores/DeleteAllocationModalStore";
import { SettingsModalStore } from "@src/stores/SettingsModalStore";
import { TaskFormModalStore } from "@src/stores/TaskFormModalStore";
import { TimeTrackingPopoverStore } from "@src/stores/TimeTrackingPopoverStore";
import { TimeTrackingStore } from "@src/stores/TimeTrackingStore";
import { TrackedForDayModalStore } from "@src/stores/TrackedForDayModalStore";
import { UIStore } from "@src/stores/UIStore/UIStore";
import { UtilizationReportStore } from "@src/stores/UtilizationReportStore";
import { UtilizationUserReportModalStore } from "@src/stores/UtilizationUserReportModalStore";
import { WorkspaceStore } from "@src/stores/WorkspaceStore/WorkspaceStore";
import { createContext } from "react";
import { FilterTemplatesStore } from "./FilterTemplatesStore";
import { ResourcePlanningDayStore } from "./ResourcePlanningDayStore";
import { ResourcePlanningWeekStore } from "./ResourcePlanningWeekStore";
import { SelectWorkTypeIfUserNotInTaskModalStore } from "./SelectWorkTypeIfUserNotInTaskModaStore";
import { EditUserModalStore } from "@src/stores/EditUserModalStore";

export class AppStore {
  authStore: AuthStore = new AuthStore(this);
  workspaceStore: WorkspaceStore = new WorkspaceStore(this);
  UIStore: UIStore = new UIStore(this);
  settingsModalStore = new SettingsModalStore(this);

  /**
   * Users
   */  
  editUserModalStore: EditUserModalStore = new EditUserModalStore(this);

  /**
   * Time tracking
   */
  timeTrackingStore: TimeTrackingStore = new TimeTrackingStore(this);
  timeTrackingPopoverStore: TimeTrackingPopoverStore =
    new TimeTrackingPopoverStore(this);

  /**
   * Reports
   */
  utilizationReportStore: UtilizationReportStore = new UtilizationReportStore(
    this,
  );
  utilizationUserReportModalStore: UtilizationUserReportModalStore =
    new UtilizationUserReportModalStore(this);

  budgetsExportStore: BudgetsExportStore = new BudgetsExportStore(this);

  /**
   * Project management
   */
  taskFormModalStore: TaskFormModalStore = new TaskFormModalStore(this);
  tasksListingStore: TasksListingStore = new TasksListingStore(this);
  // TODO: rename store file after Forecasting merge to avoid conflicts
  taskDetailModalStore: ModalCommunicationStore = new ModalCommunicationStore(
    this,
  );
  projectCreateBudgetItemModalStore: ProjectCreateBudgetItemModalStore =
    new ProjectCreateBudgetItemModalStore(this);
  projectCategorySelectStore: ProjectCategorySelectStore =
    new ProjectCategorySelectStore(this);
  projectCategoryModalStore: ProjectCategoryModalStore =
    new ProjectCategoryModalStore(this);

  /**
   * Resource planning
   */
  resourcePlanningDayStore: ResourcePlanningDayStore =
    new ResourcePlanningDayStore(this);
  resourcePlanningWeekStore: ResourcePlanningWeekStore =
    new ResourcePlanningWeekStore(this);
  allocationModalStore: AllocationModalStore = new AllocationModalStore(this);
  completeCapacityAllocationModalStore: CompleteCapacityAllocationModalStore =
    new CompleteCapacityAllocationModalStore(this);
  deleteAllocationModalStore: DeleteAllocationModalStore =
    new DeleteAllocationModalStore(this);
  trackedForDayModalStore: TrackedForDayModalStore =
    new TrackedForDayModalStore(this);
  changeTimeTrackingWorkTypeModalStore: ChangeTimeTrackingWorkTypeModalStore =
    new ChangeTimeTrackingWorkTypeModalStore(this);
  sendInvoiceModalStore: SendInvoiceModalStore = new SendInvoiceModalStore(
    this,
  );
  selectWorkTypeIfUserNoInTaskModalStore: SelectWorkTypeIfUserNotInTaskModalStore =
    new SelectWorkTypeIfUserNotInTaskModalStore(this);
  notificationsStore: NotificationsStore = new NotificationsStore(this);
  filterTemplatesStore: FilterTemplatesStore = new FilterTemplatesStore(this);
}

activateI18n("en");
export const appStore = new AppStore();

export const AppStoreContext = createContext<AppStore>(appStore);
