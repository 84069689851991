import { setTag, setUser } from "@sentry/nextjs";
import { LoginDocument, LoginQuery } from "@src/__generated__/urql-graphql";
import { API_BASE_URL } from "@src/config/constants";
import { TPages, permissionRouteMap } from "@src/config/routes";
import { trackEvent } from "@src/services/amplitude";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { TwoFactorAuthenticationStore } from "@src/stores/AuthStore/2FactorAuthenticationStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ForgottenPasswordFormState } from "@src/stores/forms/ForgottenPasswordFormState";
import { LoginFormState } from "@src/stores/forms/LoginFormState";
import { can } from "@src/utils/components/permissions";
import { action, makeObservable, observable } from "mobx";
import Router from "next/router";
import { Route, route } from "nextjs-routes";
import { Me } from "../models/Me";

export class AuthStore implements BaseStore {
  appStore: AppStore;
  twoFactorAuthenticationStore: TwoFactorAuthenticationStore;
  readonly PUBLIC_PAGES: Route["pathname"][] = [
    "/accept_email_delivery",
    "/auth/two-factor/challenge",
    "/auth/two-factor/confirm",
    "/auth/two-factor/enable",
    "/auth/two-factor/success",
    "/forgot-password",
    "/login",
  ];

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
    this.twoFactorAuthenticationStore = new TwoFactorAuthenticationStore(
      appStore,
    );
  }

  private tokenAbortController = new AbortController();
  async getCsrfToken() {
    try {
      // Abort previous request to prevent race condition.
      this.tokenAbortController.abort("New token was requested"); // eslint-disable-line lingui/no-unlocalized-strings

      this.tokenAbortController = new AbortController();
      const response = await fetch(`${API_BASE_URL}/fe-get-token`, {
        credentials: "include",
        signal: this.tokenAbortController.signal,
      });
      const data = (await response.json()) as { token: string };
      return data.token;
    } catch (e) {
      console.error(e);
    }

    return undefined;
  }

  async apiRequest(
    endpoint: string,
    body?: Record<string | number | symbol, unknown>,
    method: string = "POST",
  ) {
    return await fetch(`${API_BASE_URL}${endpoint}`, {
      credentials: "include",
      method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });
  }

  /*
   * Authentication
   */
  @observable authenticationStatus: "loading" | "error" | "success" = "loading";
  @observable authenticationStatusCode?: number;
  @observable.ref user?: Me;

  async authenticate() {
    this.setAuthenticationStatus("loading");
    const { data, error } = await client.query<LoginQuery>(
      LoginDocument,
      {},
      {
        fetch: async (...args) => {
          const response = await fetch(...args);
          this.setAuthenticationStatusCode(response.status);
          return response;
        },
        requestPolicy: "cache-first",
      },
    );

    if (error || !data) {
      this.setAuthenticationStatus("error");
      return;
    }

    if (data.me) this.user = new Me(data.me);

    setUser({
      id: this.user?.id,
      email: this.user?.email,
      username: this.user?.full_name,
    });
    setTag("allfred_workspace", window.location.host.split(".")[0]);

    this.appStore.workspaceStore.from(data);

    trackEvent("login");

    if ("function" === typeof window.FreshworksWidget) {
      window.FreshworksWidget("identify", "ticketForm", {
        name: this.user?.full_name,
        email: this.user?.email,
      });
    }

    this.setAuthenticationStatus("success");

    this.appStore.notificationsStore.startTrackingNotifications();
  }

  @action.bound private setAuthenticationStatus(
    status: typeof AuthStore.prototype.authenticationStatus,
  ) {
    this.authenticationStatus = status;
  }

  @action.bound private setAuthenticationStatusCode(
    code: typeof AuthStore.prototype.authenticationStatusCode,
  ) {
    this.authenticationStatusCode = code;
  }

  /*
   * Login
   */
  loginForm = new LoginFormState();
  @observable remoteLoginUrl?: string;

  @action.bound setLoginFormState(
    state: typeof LoginFormState.prototype.state,
  ) {
    this.loginForm.state = state;
  }

  async initLogin(redirect_to?: string) {
    this.setLoginFormState("loading");

    if (redirect_to) {
      this.loginForm.redirect_to = redirect_to;
    }

    this.setLoginFormCsrfToken();

    try {
      const response = await this.apiRequest(
        "/remote-login-url",
        undefined,
        "GET",
      );

      const data = (await response.json()) as {
        url: string;
      };

      this.remoteLoginUrl = data.url;
    } catch {}
  }

  async setLoginFormCsrfToken() {
    const token = await this.getCsrfToken();
    if (!token) {
      this.setLoginFormState("error");
      return;
    }
    this.loginForm._token = token;
    this.setLoginFormState("ready");
  }

  async logIn() {
    this.setLoginFormState("submitting");

    await this.loginForm.form.validate();

    try {
      const response = await this.apiRequest("/fe-login", {
        _token: this.loginForm._token,
        redirect_to: this.loginForm.redirect_to || undefined,
        email: this.loginForm.email.$,
        password: this.loginForm.password.$,
        remember: this.loginForm.remember.$,
      });

      if (response.status === 200) {
        const data = (await response.json()) as
          | { two_factor: boolean }
          | { status: string };

        // If `two_factor` is present, user has two-factor authentication enabled.
        if ("two_factor" in data) {
          Router.push({
            pathname: route({ pathname: "/auth/two-factor/challenge" }),
            query: { redirect_to: this.loginForm.redirect_to },
          });
          return;
        }

        // Otherwise user is logged in and we can redirect to the requested page.
        window.location.href = this.loginForm.redirect_to || "/";
        return;
      }

      const data = (await response.json()) as {
        message: string;
        errors?: {
          email?: string[];
          password?: string[];
        };
      };

      if (data.errors) {
        if (data.errors.email) {
          this.loginForm.email.setError(data.errors.email[0]);
        }
        if (data.errors.password) {
          this.loginForm.password.setError(data.errors.password[0]);
        }

        if (this.loginForm.form.hasError) {
          await this.setLoginFormCsrfToken();
          return;
        }
      }
    } catch {}

    this.setLoginFormState("error");
  }

  /*
   * Forgotten password
   */
  forgottenPasswordForm = new ForgottenPasswordFormState();

  @action.bound setForgottenPasswordForm(
    state: typeof ForgottenPasswordFormState.prototype.state,
  ) {
    this.forgottenPasswordForm.state = state;
  }

  async initForgottenPassword() {
    this.setForgottenPasswordForm("loading");
    await this.setForgottenPasswordFormCsrfToken();
  }

  async setForgottenPasswordFormCsrfToken() {
    const token = await this.getCsrfToken();
    if (!token) {
      this.setForgottenPasswordForm("error");
      return;
    }
    this.forgottenPasswordForm._token = token;
    this.setForgottenPasswordForm("ready");
  }

  async sendPasswordResetLink() {
    this.setForgottenPasswordForm("submitting");

    try {
      const response = await this.apiRequest("/forgot-password", {
        _token: this.forgottenPasswordForm._token,
        email: this.forgottenPasswordForm.email.$,
      });

      if (response.status < 300) {
        this.setForgottenPasswordForm("success");
        this.forgottenPasswordForm.form.reset();
        return;
      }

      const data = (await response.json()) as {
        message: string;
        errors?: {
          email?: string[];
        };
      };

      if (data.errors && data.errors.email) {
        this.forgottenPasswordForm.email.setError(data.errors.email[0]);
        await this.setForgottenPasswordFormCsrfToken();
        return;
      }
    } catch {}

    this.setForgottenPasswordForm("error");
  }

  /**
   * Authorization
   */
  hasPermissionForPage(_module: TPages) {
    const modulePermissions = permissionRouteMap.get(_module);
    if (!modulePermissions) return false;
    return can(modulePermissions);
  }
}
