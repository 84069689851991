import { TaskComment, TaskQuery } from "@src/__generated__/graphql";
import { SimpleTaskFragment, Task } from "@src/__generated__/urql-graphql";
import { IOption } from "@src/components/ui-kit";
import { TASK_ID_QUERY_KEY } from "@src/components/widgets/Modals/ModalCommunication/CommunicationModalHeader";
import { ModalCommunication } from "@src/components/widgets/Modals/ModalCommunication/ModalCommunication";
import { TaskModel } from "@src/components/widgets/Modals/ModalCommunication/models";
import { CommentModel } from "@src/components/widgets/Modals/ModalCommunication/models/CommentModel";
import { TaskTabIdEnum } from "@src/constants/tasks";
import { trackEvent } from "@src/services/amplitude";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { BooleanState } from "@src/utils/mobx/states/BooleanState";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { UnionState } from "@src/utils/mobx/states/UnionState";
import { ValueState } from "@src/utils/mobx/states/ValueState";
import { omit } from "lodash";
import { computed, makeObservable, observable } from "mobx";
import router from "next/router";
import React, { createRef } from "react";

type DescriptionState = "write" | "read";
type ModalCommunicationOptions = {
  id: Task["id"];
  onChange?: (task: TaskModel | SimpleTaskFragment) => void;
  onDelete?: (task: TaskModel) => void;
  onDuplicate?: (task: SimpleTaskFragment) => void;
  onTimeTrackingItemCreated?: (trackedTime: number | undefined) => void;
};

// TODO: rename store file to `TaskDetailModalStore` after Forecasting merge to avoid conflicts
export class ModalCommunicationStore implements BaseStore, ModalStore {
  appStore: AppStore;

  readonly modalId = "taskDetailModal";

  drawerState = new DisclosureState<ModalCommunicationOptions>({
    onOpen: (additionalData) => {
      trackEvent("task", "Opening task drawer");
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <ModalCommunication />,
      });
      if (additionalData?.id) {
        this.taskId.set(additionalData?.id);
        this.descriptionState.set("read");
        this.seenLastComment.off();
        if (!router.query[TASK_ID_QUERY_KEY]) {
          router.replace(
            {
              pathname: router.pathname,
              query: {
                ...router.query,
                [TASK_ID_QUERY_KEY]: additionalData?.id,
              },
            },
            undefined,
            { shallow: true },
          );
        }
      }
    },
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
      router.replace(
        {
          pathname: router.pathname,
          query: omit(router.query, TASK_ID_QUERY_KEY),
        },
        undefined,
        { shallow: true },
      );
    },
  });
  task = new ValueState<TaskModel | null>(null);
  taskStatuses = new ValueState<TaskQuery["taskStatuses"]>([]);
  activeTabId = new ValueState<TaskTabIdEnum>(TaskTabIdEnum.Description);
  comments = new ValueState<CommentModel[]>([]);
  commentsPagination = new PaginationState("comments");
  taskId = new ValueState<string | undefined>(undefined);
  attachmentsCollapsed = new BooleanState();
  isLoading = new BooleanState();
  isLoadingDuplicate = new BooleanState();
  @observable priorityOptions: IOption[] = [];

  deleteModal = new DisclosureState();
  editTaskModal = new DisclosureState<{ moveMode: boolean }>();

  firstCommentRef: React.RefObject<HTMLDivElement> = createRef();
  commentsContainerRef: React.RefObject<HTMLDivElement> = createRef();
  commentsLoaderRef: React.RefObject<HTMLDivElement> = createRef();
  drawerBodyRef = createRef<HTMLDivElement>();

  @observable newlyCreatedCommentID?: TaskComment["id"] = undefined;
  seenLastComment = new BooleanState();
  needsRevision = new BooleanState();
  fetchTask: (() => void) | undefined = undefined;

  @observable descriptionLoading = new BooleanState(false);

  descriptionState = new UnionState<DescriptionState>("read");
  @observable descriptionChanged = false;
  @observable commentChanged = false;

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @computed get statusOptions() {
    return this.taskStatuses.value.map((status) => ({
      label: status.name,
      value: status.id,
    }));
  }
}
