import { HStack } from "@chakra-ui/react";
import {
  FormRow,
  InputDatePicker,
  Select,
  TimeInput,
} from "@components/ui-kit";
import { t } from "@lingui/macro";
import {
  useTimeTrackingOptionsForProjectQuery,
  useTimeTrackingProjectsQuery,
} from "@src/__generated__/graphql";
import { BillableButton } from "@src/components/ui-kit/BillableButton";
import {
  TextEditor,
  TextEditorRef,
} from "@src/components/ui-kit/TextEditor/TextEditor";
import { useSetEditorContent } from "@src/components/ui-kit/TextEditor/hooks/useSetContent";
import { onError } from "@src/utils/apollo";
import { toApiDate } from "@src/utils/dates";
import {
  fieldToInputProps,
  fieldToSelectProps,
} from "@src/utils/forms/inputHelpers";
import { useStore } from "@src/utils/hooks";
import map from "@src/utils/map-to-options";
import {
  TaskSelect,
  TaskSelectProps,
} from "@src/widgets/TaskSelect/TaskSelect";
import { observer } from "mobx-react-lite";
import { now } from "mobx-utils";
import React, { useEffect, useMemo, useRef } from "react";
import { EntryForm } from "../form";

interface FormProps {
  value: EntryForm;
}

const Form = ({ value: form }: FormProps) => {
  const { authStore } = useStore();
  const editorRef = useRef<TextEditorRef>(null);

  const projectsReq = useTimeTrackingProjectsQuery({
    ...onError(),
  });

  const projectsOptions = useMemo(() => {
    if (!projectsReq.data?.projectsSimpleMap) return [];
    return map.projects(projectsReq.data.projectsSimpleMap);
  }, [projectsReq.data]);

  const projectReqDate = useMemo(() => {
    return toApiDate(form.tracked_for_date.value ?? new Date(now()));
  }, [form.tracked_for_date.value]);

  const forProjectReq = useTimeTrackingOptionsForProjectQuery({
    variables: {
      id: form.project_id.value,
      date: projectReqDate,
    },
    ...onError(),
  });

  const [ourWorkOptions, workTypeOptions] = useMemo(() => {
    const forProject = forProjectReq.data?.timeTrackingOptionsForProject;
    if (!forProject) return [[], []];

    const ourWorkOptions = forProject.ourWork.flatMap((i) => {
      if (!i.timeTrackingSettings.tracking_enabled) return [];

      return [{ label: `${i.title} - ${i.category.name}`, value: i.id }];
    });
    const workTypeOptions = map.toOptions(
      forProject.availableTimeTrackingWorkTypes,
      "title",
    );
    return [ourWorkOptions, workTypeOptions];
  }, [forProjectReq.data]);

  // preset ourWork
  useEffect(() => {
    if (!form.budget_item_id.value && ourWorkOptions.length === 1) {
      form.budget_item_id.onChange(ourWorkOptions[0].value);
    }
  }, [form.budget_item_id.value, ourWorkOptions]);

  // preset workType
  useEffect(() => {
    if (
      !form.time_tracking_work_type_id.value &&
      workTypeOptions.length === 1
    ) {
      form.time_tracking_work_type_id.onChange(workTypeOptions[0].value);
    }
  }, [form.time_tracking_work_type_id.value, workTypeOptions]);

  // preset workType by task
  useEffect(() => {
    if (
      form.time_tracking_work_type_id.value ||
      !form.task_id.value ||
      workTypeOptions.length === 0
    )
      return;

    const myTaskPositions =
      forProjectReq.data?.timeTrackingOptionsForProject.ourWork
        .flatMap((i) => i.tasks)
        .find((i) => i.id === form.task_id.value)
        ?.positions.filter((i) => i.user?.id === authStore.user?.id);
    if (myTaskPositions && myTaskPositions.length === 1) {
      form.time_tracking_work_type_id.onChange(
        myTaskPositions[0].timeTrackingWorkType.id,
      );
      return;
    }

    if (
      workTypeOptions.find(
        (i) => i.value === authStore.user?.profile.defaultWorktype.id,
      )
    ) {
      form.time_tracking_work_type_id.onChange(
        authStore.user!.profile.defaultWorktype.id,
      );
    }
  }, [form.task_id.value, forProjectReq.data?.timeTrackingOptionsForProject]);

  useSetEditorContent({
    ref: editorRef?.current,
    note: form.notes.value,
  });

  const onSelectProject = (val: string) => {
    form.project_id.onChange(val);
    resetOptionsForProject();
  };

  const onSelectBudgetItem = (val: string | undefined) => {
    form.budget_item_id.onChange(val ?? "");
    form.task_id.reset();
  };

  const resetOptionsForProject = () => {
    form.budget_item_id.reset();
    form.task_id.reset();
    form.time_tracking_work_type_id.reset();
  };

  const onTaskSelectChange = (
    ...[id, { budgetItemId, projectId, label, billable }]: Parameters<
      NonNullable<TaskSelectProps["onChange"]>
    >
  ) => {
    form.task_id.onChange(id);
    if (label) {
      form.selectedTask = { value: id, label };
    }
    budgetItemId && form.budget_item_id.onChange(budgetItemId);
    projectId && form.project_id.onChange(projectId);
    form.billable.onChange(billable ?? false);
  };

  return (
    <React.Fragment>
      <FormRow title={t`Task`}>
        <TaskSelect
          disableDoneTasks
          hideNonTrackableTasks
          placeholder={t`Select task`}
          onChange={onTaskSelectChange}
          value={form.task_id.value}
          projectFilter={
            form.project_id.value ? [form.project_id.value] : undefined
          }
          budgetItemFilter={
            form.budget_item_id.value ? [form.budget_item_id.value] : undefined
          }
          asPortal
          error={form.task_id.error}
          defaultOptions={form.selectedTask ? [form.selectedTask] : undefined}
          options={[]}
        />
      </FormRow>

      <FormRow title={t`Project`}>
        <Select
          isClearable
          placeholder={t`Select project`}
          isLoading={projectsReq.loading}
          value={form.project_id.value}
          error={form.project_id.error}
          options={projectsOptions}
          onChange={onSelectProject}
        />
      </FormRow>

      <FormRow title={t`Budget item`}>
        <Select
          isClearable
          placeholder={t`Select budget item`}
          isLoading={forProjectReq.loading}
          value={form.budget_item_id.value}
          error={form.budget_item_id.error}
          onChange={onSelectBudgetItem}
          options={ourWorkOptions}
        />
      </FormRow>

      <FormRow title={t`Position`}>
        <Select
          placeholder={t`Select position`}
          isLoading={forProjectReq.loading}
          {...fieldToSelectProps(
            form.time_tracking_work_type_id,
            workTypeOptions,
          )}
        />
      </FormRow>

      <FormRow
        alignItems="start"
        title={
          <HStack spacing="2">
            <BillableButton field={form.billable} size="lg" />
            <TimeInput
              height="60px"
              fontSize="3rem"
              value={form.time.value}
              onChange={(val) => form.time.onChange(val)}
            />
          </HStack>
        }
      >
        <TextEditor
          ref={editorRef}
          attachments={[]}
          placeholder={t`Notes`}
          h="60px"
          overflow="hidden"
          maxH="60px"
          px="3"
          minHeight="60px"
          borderWidth="thin"
          initialValue={form.notes.value}
          borderColor="grey.200"
          borderRadius="base"
          transition="border-color 150ms ease-in"
          _focusWithin={{
            borderColor: "purple.500",
          }}
          {...fieldToInputProps(form.notes)}
        />
      </FormRow>
      {form.editMode && (
        <FormRow title={t`Date`}>
          <InputDatePicker
            onChange={(val) => {
              if (val) {
                form.tracked_for_date.onChange(val.start);
              }
            }}
            selected={form.tracked_for_date.value}
          />
        </FormRow>
      )}
    </React.Fragment>
  );
};

export default observer(Form);
